import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{staticClass:"user-bio-panel"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"pt-10"},[_c(VCardTitle,{staticClass:"justify-center flex-column"},[_c(VAvatar,{staticClass:"mb-4",class:_vm.data.image ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":_vm.data.image ? '' : 'primary',"size":"120","rounded":""}},[(_vm.data.thumbUrl)?_c(VImg,{attrs:{"src":_vm.data.thumbUrl}}):_c('span',{staticClass:"font-weight-semibold text-5xl"},[_vm._v(_vm._s(_vm.avatarText(_vm.data.title)))])],1),_c('span',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm._f("textEllipsis")(_vm.data.title,90)))]),_c(VChip,{staticClass:"v-chip-light-bg text-sm font-weight-semibold primary--text text-capitalize",attrs:{"label":"","small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm._f("textEllipsis")(_vm.data.color,55))+" ")])],1),(_vm.$can('update', 'Tip'))?_c(VCardActions,{staticClass:"justify-center"},[_c(VBtn,{attrs:{"color":"primary","to":{ name: 'tip-edit', params: { id: _vm.data.id } }}},[_vm._v(" "+_vm._s(_vm.t('Edit'))+" ")])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div id="user-view">
    <v-row>
      <v-col
        cols="12"
        md="5"
        lg="4"
      >
        <tip-bio-panel
          v-if="tip"
          :data="tip"
          :handle-update="updateData"
        ></tip-bio-panel>
      </v-col>

      <v-col
        cols="12"
        md="7"
        lg="8"
      >
        <v-tabs
          v-model="tipTab"
          show-arrows
          class="user-tabs"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
            :append="tab.append"
            :to="tab.to"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="user-tabs-content"
          v-model="tipTab"
          class="mt-5 pa-1"
        >
          <v-tab-item
            v-if="tip"
            value="overview"
          >
            <tip-tab-overview :data="tip"></tip-tab-overview>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline, no-unused-vars
import { onUnmounted, ref, provide } from '@vue/composition-api'
import { mdiAccountOutline } from '@mdi/js'
import { useRouter } from '@core/utils'

import useTipView from './useTipView'
import TipBioPanel from './tip-bio-panel/TipBioPanel.vue'
import TipTabOverview from './tip-tab-overview/TipTabOverview.vue'

export default {
  components: {
    TipBioPanel,
    TipTabOverview,
  },
  setup() {
    const tipTab = ref(null)
    const { route } = useRouter()

    const {
      tip,
      loading,
      updating,

      errorMessages,

      init,
      destroy,

      updateData,
    } = useTipView(route.value.params.id)

    // Init
    init()

    // Destroy
    onUnmounted(() => {
      destroy()
    })

    const tabs = [
      {
        icon: mdiAccountOutline,
        title: 'Overview',
        append: true,
        to: '#overview',
      },
    ]

    provide('tipUpdating', updating)
    provide('tipErrorMessages', errorMessages)

    return {
      tabs,
      tipTab,

      tip,
      loading,
      updating,
      updateData,

      errorMessages,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
